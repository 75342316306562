import {
  configureStore,
  combineReducers,
  AnyAction,
  createSlice,
} from "@reduxjs/toolkit";

import { persistStore, persistReducer } from "reduxjs-toolkit-persist";
import localStorage from "reduxjs-toolkit-persist/lib/storage";

import { multisiteTagsReducer } from "./store/multisiteTagsStore";
import { sitesMetadataReducer } from "./store/SitesStore";
import { multiSitesVisualInfoReducer } from "./store/SiteVisualInfoStore";
import { multiSitesTagsChartReducer } from "./store/TagsChart";
import { multiSitesTagsMetadataReducer } from "./store/TagsMetadataStore";
import { multiSitesTrackersCommandsReducer } from "./store/TrackersCommandsStore";
import { multiSitesTrackersReducer } from "./store/TrackersStore";
import { multiSitesUserRecordsReducer } from "./store/UserRecords";
import { pagesReducer } from "../../pages/PagesStore";
import { multiSitesIssuesReducer } from "./store/IssuesStore";
import { trendsReducer } from "../multisite_trends/TrendsStore";
import { siteReducer } from "../sites/SiteStore";
import { userReducer } from "../user/UserStore";
import { multiSitesAlertsReducer } from "./store/AlertsStore";
import { safeOverrideReducer } from "./store/SafeOverrideStore";
import { faultsPageReducer } from "./store/FaultsPageStore";
import featuresReducer from "../featureFlags/FeatureFlagsStore";

const rootPersistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["user"],
};

export const resetSlice = createSlice({
  name: "reset",
  initialState: {},
  reducers: {
    clearResults() {
      //handled in the root reducer
    },
  },
});

const appReducer = combineReducers({
  user: userReducer,
  pages: pagesReducer,
  reset: resetSlice.reducer,
  trends: trendsReducer,
  multiSitesIssues: multiSitesIssuesReducer,
  multiSitesUserRecords: multiSitesUserRecordsReducer,
  multiSitesTrackersCommands: multiSitesTrackersCommandsReducer,
  site: siteReducer,
  multiSitesMetadata: sitesMetadataReducer,
  multiSitesVisualInfo: multiSitesVisualInfoReducer,
  multiSitesTags: multisiteTagsReducer,
  multiSitesTagsMetadata: multiSitesTagsMetadataReducer,
  multiSitesChartsData: multiSitesTagsChartReducer,
  multiSitesTrackers: multiSitesTrackersReducer,
  multiSitesAlerts: multiSitesAlertsReducer,
  safeOverride: safeOverrideReducer,
  faultsPage: faultsPageReducer,
  featureFlags: featuresReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "reset/clearResults") {
    state = {
      user: state.user,
      multiSitesTagsMetadata: state.multiSitesTagsMetadata,
      multiSitesVisualInfo: state.multiSitesVisualInfo,
      multiSitesMetadata: state.multiSitesMetadata,
      multiSitesChartsData: state.multiSitesChartsData,
      multiSitesTrackersCommands: state.multiSitesTrackersCommands,
      multiSitesUserRecords: state.multiSitesUserRecords,
      multiSitesTrackers: state.multiSitesTrackers,
      multiSitesIssues: state.multiSitesIssues,
      multiSitesAlerts: state.multiSitesAlerts,
      faultsPage: state.faultsPage,
      featureFlags: state.featureFlags,
    };
  }
  return appReducer(state, action);
};

const persistedReducer: typeof rootReducer = persistReducer(
  rootPersistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
